@font-face {
  font-family: inter;
  src: url("../public/font/Inter-Regular.ttf");
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: inter;
}

:root {
  --primary-clr: #363636;
  --secondary-clr: #888888;
  --active-btn: #c1c1c1;
  --not-active-btn: #ededed;
  --btn-border: #363636;
}

body {
  --sb-track-color: #232e33;
  --sb-thumb-color: #000000;
  --sb-size: 1px;
}

body::-webkit-scrollbar {
  width: var(--sb-size);
}

body::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 1px;
}

body::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 1px;
}

@supports not selector(::-webkit-scrollbar) {
  body {
    scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
  }
}

label {
  font-size: 12px;
  color: var(--primary-clr);
  font-weight: 600;
}

input::placeholder {
  font-size: 14px;
  font-weight: 500;
  color: var(--secondary-clr);
}

.button {
  background-color: rgb(76, 74, 74);
  color: white;
  border-radius: 10px;
}

.folder-bg-color {
  background-color: #c3c0c0;
  color: black;
  font-weight: 600;
  border: 1px solid #706f6fa6 !important;
  border-radius: 10px;
}

.sub-folder-bg-color {
  background-color: #e2dede;
  border: 1px solid #706f6fa6 !important;
  border-radius: 10px;
}
.css-1ydjfm6[data-checked] {
  background: black !important;
  border-color: black !important;
}

.select .css-13cymwt-control {
  background-color: #f7f7f7;
}

.css-1ydjfm6 {
  border-color: black !important;
}

.active-btn {
  background-color: var(--active-btn);
  color: black !important;
  border: 1px solid var(--btn-border) !important;
}

.not-active-btn {
  background-color: var(--not-active-btn);
  color: black !important;
  border: 1px solid var(--btn-border) !important;
}
